import React from 'react';
import '../../App.css';
import Home from '../Home';
import Cards from '../About';
import Footer from '../Footer';

function AboutPage(){
    return(
        <>
            <Cards />
            <Footer />
        </>
    );
}

export default AboutPage;
