import React from 'react';
import '../../App.css';
import Home from '../Home';
import Cards from '../About';
import Footer from '../Footer';

function HomePage(){
    return(
        <>
            <Home />
            <Footer />
        </>
    );
}

export default HomePage;

