import React from 'react';
import '../../App.css';
import Cards from '../WorkTerm';
import Footer from '../Footer';

function WorkTermPage(){
    return(
        <>
            <Cards />
            <Footer />
        </>
    );
}

export default WorkTermPage;